import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import isEmail from "validator/lib/isEmail";
import isAlpha from "validator/lib/isAlpha";
import TimezoneToggle from "./TimezoneToggle";
import CalendarWrapper from "./Calendar";
import offsettedDate from "../../utils/dateUtils";
import "./schedule.scss";
import ScheduleTypeToggle from "./ScheduleTypeToggle";
import BasicSpinner from "./Spinner";
import validPrograms from "../../constants/validPrograms";
import Claim from "./Claim";
import Transfer from "./Transfer";
import RescheduleForm from "./RescheduleForm";
import Toast from "./Toast";
import { getOffsetFromUTC } from "../../utils/dateUtils";
import { startOfWeek, getHours } from "date-fns";
import ShiftModal from "./ShiftModal";
import { Button } from "react-bootstrap";
import NewShiftForm from "./NewShiftForm";

function Schedule({ schedule, user, is_admin }) {
  const [timezone, setTimezone] = useState("PST");
  const [formattedSchedule, setFormattedSchedule] = useState([]);
  const [currentScheduleDisplayed, setCurrentScheduleDisplayed] = useState("My Schedule");
  const [isLoading, setIsLoading] = useState(false);
  const [currentSchedule, setCurrentSchedule] = useState(schedule);
  const [currentRange, setCurrentRange] = useState();
  const [selectedShift, setSelectedShift] = useState();
  const [mentorNameOrEmail, setMentorNameOrEmail] = useState("");
  const [foundMentors, setFoundMentors] = useState(undefined);
  const [transferFormValid, setTransferFormValid] = useState(true);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [newShift, setNewShift] = useState(null);

  const userTimezone = () => {
    try {
      const systemTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      setTimezone(systemTimezone);
    } catch (error) {
      console.error(`Failed to get user timezone: ${error.message}`);
      setTimezone("PST");
    }
  };

  const formatSchedule = () =>
    currentSchedule?.map((record) => ({
      ...record,
      start: offsettedDate(record.start, timezone),
      end: offsettedDate(record.end, timezone),
    }));

  useEffect(() => {
    userTimezone();
  }, []);

  useEffect(() => {
    if (timezone) {
      const mappedSchedule = formatSchedule();
      setFormattedSchedule(mappedSchedule);
    }
  }, [timezone, currentSchedule]);

  const setToMonday = (date) => {
    const day = date.getDay() || 7;
    if (day !== 1) {
      date.setHours(-24 * (day - 1));
    }
    return date.toISOString().split("T")[0];
  };

  const fetchProgram = async (searchType, range = currentRange) => {
    try {
      const response = await fetch(
        `/schedule/program?program=${searchType}&week_of=${
          range || setToMonday(new Date())
        }`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        const data = await response.json();
        console.log("data fetch program", data, searchType);
        setCurrentSchedule(data);
        setIsLoading(false);
        setShowErrorToast(false);
        setShowSuccessToast(false);
      } else {
        throw new Error("Unable to fetch program");
      }
    } catch (e) {
      setShowErrorToast(true);
      setShowSuccessToast(false);
      setIsLoading(false);
    }
  };

  const fetchIndividualSchedule = async (range = currentRange) => {
    try {
      const response = await fetch(
        `/schedule?week_of=${range || setToMonday(new Date())}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        setCurrentSchedule(data);
        setIsLoading(false);
        setShowErrorToast(false);
        setShowSuccessToast(false);
      } else {
        throw new Error("Unable to fetch schedule");
      }
    } catch (e) {
      setShowErrorToast(true);
      setShowSuccessToast(false);
      setIsLoading(false);
    }
  };

  const onRangeChange = async (range) => {
    // We want to refetch data:
    // - every time the user navigates from one week to another (when looking at the calendar week view)
    // - when a user navigates from Sunday -> Monday (when looking at the calendar day view)
    // - when a user navigates from Monday -> Sunday (when looking at the calendar day view)
    if (range.length === 7 || range[0].getDay() === 1 || range[0].getDay() === 0) {
      setIsLoading(true);
      let monday;
      // Condition 1 + 2 above
      if (range[0].toString().includes("Mon")) {
        const selectedRange = range[0];
        // Get the date in the format we need (need to make sure we offset it otherwise when we do toISOString it might change days)
        monday = new Date(
          selectedRange.getTime() - selectedRange.getTimezoneOffset() * 60000
        ).toISOString();
        // Getting the date without the time
        monday = monday.substring(0, 10);
      } else {
        const selectedRange = range[0];
        selectedRange.setDate(selectedRange.getDate() - 6);
        monday = new Date(
          selectedRange.getTime() - selectedRange.getTimezoneOffset() * 60000
        )
          .toISOString()
          .substring(0, 10);
      }

      setCurrentRange(monday);

      try {
        if (validPrograms.find((program) => program.name === currentScheduleDisplayed)) {
          const searchType = validPrograms.find(
            (program) => program.name === currentScheduleDisplayed
          ).tag;
          await fetchProgram(searchType, monday);
        } else {
          await fetchIndividualSchedule(monday);
        }
      } catch (e) {
        setShowErrorToast(true);
        setShowSuccessToast(false);
        setIsLoading(false);
      }
    }
  };

  const onScheduleTypeChanged = useCallback(async (type) => {
    setIsLoading(true);

    try {
      if (validPrograms.find((program) => program.name === type)) {
        const searchType = validPrograms.find((program) => program.name === type).tag;
        await fetchProgram(searchType);
      } else {
        await fetchIndividualSchedule();
      }
      setCurrentScheduleDisplayed(type);
      setShowErrorToast(false);
      setShowSuccessToast(false);
    } catch (e) {
      setShowErrorToast(true);
      setShowSuccessToast(false);
      setIsLoading(false);
    }
  });

  const claimShift = async (user_id = null) => {
    try {
      setIsLoading(true);

      const {
        week_of,
        dow,
        shift_hour: hour,
        tags,
        id: existing_shift_id,
      } = selectedShift;

      const response = await fetch("/schedule/claim", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          claim: {
            week_of,
            dow,
            hour,
            tags,
            existing_shift_id,
            ...(user_id ? { user_id } : {}),
          },
        }),
      });

      if (response.status === 200) {
        setSelectedShift(null);
        onScheduleTypeChanged(currentScheduleDisplayed);
        setFoundMentors(null);
        setMentorNameOrEmail("");
        setShowSuccessToast(true);
        setShowErrorToast(false);
      } else {
        setShowErrorToast(true);
        setShowSuccessToast(false);
        setIsLoading(false);
      }
    } catch (e) {
      setShowErrorToast(true);
      setShowSuccessToast(false);
      setIsLoading(false);
    }
  };

  const moveShift = async (newStartTime) => {
    const days = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ];
    const newStartTimeDate = new Date(newStartTime);
    const shift = selectedShift;

    const pacificTimeOffset = getOffsetFromUTC("America/Los_Angeles");
    const localTimeOffset = getOffsetFromUTC(timezone);
    const offsetDiff = localTimeOffset - pacificTimeOffset;

    const dow = days[shift.start.getDay()];
    const new_dow = days[newStartTimeDate.getDay()];
    const scheduleVersion = shift.week_of.split("_")[1] || "1";
    const week_of = startOfWeek(shift.start, {
      weekStartsOn: 1,
    }).toLocaleDateString("en-CA");
    const new_week_of = startOfWeek(newStartTimeDate, {
      weekStartsOn: 1,
    }).toLocaleDateString("en-CA");

    const hour = String(getHours(shift.start) - offsetDiff);
    const new_hour = String(getHours(newStartTimeDate) - offsetDiff);

    const stringifiedBody = {
      move_shift: {
        shift_id: String(shift.id),
        mentor_id: shift.user_id,
        dow,
        new_dow,
        week_of,
        new_week_of,
        hour,
        new_hour,
        schedule_version: scheduleVersion,
      },
    };

    console.log(stringifiedBody);

    try {
      setIsLoading(true);
      const response = await fetch("/schedule/move_shift", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(stringifiedBody),
      });

      if (response.status === 200) {
        setSelectedShift(null);
        onScheduleTypeChanged(currentScheduleDisplayed);
        setShowSuccessToast(true);
        setShowErrorToast(false);
      } else {
        setShowErrorToast(true);
        setShowSuccessToast(false);
        setIsLoading(false);
      }
    } catch (e) {
      setShowErrorToast(true);
      setShowSuccessToast(false);
      setIsLoading(false);
    }
  };

  const deleteShift = async () => {
    console.log("Deleting shift");
    const shift = selectedShift;

    const stringifiedBody = {
      delete_shift: {
        shift_id: String(shift.id),
      },
    };

    try {
      setIsLoading(true);
      const response = await fetch("/schedule/delete_shift", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(stringifiedBody),
      });

      if (response.status === 200) {
        setSelectedShift(null);
        onScheduleTypeChanged(currentScheduleDisplayed);
        setShowSuccessToast(true);
        setShowErrorToast(false);
      } else {
        setShowErrorToast(true);
        setShowSuccessToast(false);
        setIsLoading(false);
      }
    } catch (e) {
      setShowErrorToast(true);
      setShowSuccessToast(false);
      setIsLoading(false);
    }
  };

  const updateShiftNotes = async (note) => {
    console.log("Updating shift");
    const shift = selectedShift;

    const stringifiedBody = {
      update_shift_notes: {
        shift_id: String(shift.id),
        note,
      },
    };

    try {
      setIsLoading(true);
      const response = await fetch("/schedule/update_shift_note", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(stringifiedBody),
      });

      if (response.status === 200) {
        setSelectedShift(null);
        onScheduleTypeChanged(currentScheduleDisplayed);
        setShowSuccessToast(true);
        setShowErrorToast(false);
      } else {
        setShowErrorToast(true);
        setShowSuccessToast(false);
        setIsLoading(false);
      }
    } catch (e) {
      setShowErrorToast(true);
      setShowSuccessToast(false);
      setIsLoading(false);
    }
  };

  const createShift = async (formData) => {
    const { program, isTi, startTime, note, mentorId } = formData;
    const days = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ];
    const startTimeDate = new Date(startTime);

    const pacificTimeOffset = getOffsetFromUTC("America/Los_Angeles");
    const localTimeOffset = getOffsetFromUTC(timezone);
    const offsetDiff = localTimeOffset - pacificTimeOffset;

    const dow = days[startTimeDate.getDay()];
    const scheduleVersion = "1"; //????
    // const scheduleVersion = shift.week_of.split("_")[1] || "1"; //????
    const week_of = startOfWeek(startTimeDate, {
      weekStartsOn: 1,
    }).toLocaleDateString("en-CA");

    const hour = String(getHours(startTimeDate) - offsetDiff);

    const mentor_tags = isTi ? `${program} TI` : program;

    const stringifiedBody = {
      create_shift: {
        mentor_id: mentorId ? mentorId : "?",
        dow,
        week_of,
        hour,
        schedule_version: scheduleVersion,
        mentor_tags,
        note,
      },
    };

    console.log(stringifiedBody);

    try {
      setIsLoading(true);
      const response = await fetch("/schedule/create_shift", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(stringifiedBody),
      });

      if (response.status === 200) {
        closeAll()
        onScheduleTypeChanged(currentScheduleDisplayed);
        setShowSuccessToast(true);
        setShowErrorToast(false);
      } else {
        setShowErrorToast(true);
        setShowSuccessToast(false);
        setIsLoading(false);
      }
    } catch (e) {
      setShowErrorToast(true);
      setShowSuccessToast(false);
      setIsLoading(false);
    }
  };

  const searchForMentor = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (mentorNameOrEmail && (isEmail(mentorNameOrEmail) || isAlpha(mentorNameOrEmail))) {
      setTransferFormValid(true);
      const response = await fetch(`/schedule/mentor?name_or_email=${mentorNameOrEmail}`);

      if (response.status === 200) {
        const data = await response.json();
        setFoundMentors(data.users);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setShowErrorToast(true);
        setShowSuccessToast(false);
      }
    } else {
      setIsLoading(false);
      setTransferFormValid(false);
    }
  };

  const handleCreateShift = (formData) => {
    setNewShift(null);
    createShift(formData);
  };

  const closeAll = () => {
    setSelectedShift(null);
    setNewShift(null)
    setMentorNameOrEmail("")
    setFoundMentors(null)
  }

  if (schedule) {
    return (
      <div className="local-bootstrap ">
        <div className="d-flex flex-row justify-content-between">
          <TimezoneToggle timezone={timezone} setTimezone={setTimezone} />
          {isLoading ? <BasicSpinner /> : null}
          <div style={{ display: "flex", gap: "0.5em" }}>
            <ScheduleTypeToggle
              currentScheduleDisplayed={currentScheduleDisplayed}
              setCurrentScheduleDisplayed={onScheduleTypeChanged}
            />
            {is_admin && <Button
              onClick={() => {
                setNewShift({});
                setSelectedShift(null);
              }}
            >
              Add Shift
            </Button>}
          </div>
        </div>
        <div className={selectedShift || newShift ? "d-flex flex-row" : null}>
          <CalendarWrapper
            formattedSchedule={formattedSchedule}
            onRangeChange={onRangeChange}
            onSelectEvent={(shift) => {
              setSelectedShift(shift);
              setNewShift(null);
            }}
            user={user}
          />
          {selectedShift && (
            <ShiftModal
              is_admin={is_admin}
              selectedShift={selectedShift}
              setSelectedShift={setSelectedShift}
              mentorNameOrEmail={mentorNameOrEmail}
              timezone={timezone}
              moveShift={moveShift}
              searchForMentor={searchForMentor}
              setMentorNameOrEmail={setMentorNameOrEmail}
              foundMentors={foundMentors}
              setFoundMentors={setFoundMentors}
              claimShift={claimShift}
              transferFormValid={transferFormValid}
              user={user}
              deleteShift={deleteShift}
              updateShiftNotes={updateShiftNotes}
              currentScheduleDisplayed={currentScheduleDisplayed}
              closeAll={closeAll}
            />
          )}
          {newShift && (
            <div className="col-md-6 mt-5">
              <NewShiftForm
                createShift={handleCreateShift}
                setMentorNameOrEmail={setMentorNameOrEmail}
                mentorNameOrEmail={mentorNameOrEmail}
                foundMentors={foundMentors}
                isValid={transferFormValid}
                search={searchForMentor}
                setNewShift={setNewShift}
                closeAll={closeAll}
              />
            </div>
          )}
        </div>
        <Toast show={showErrorToast} setShow={setShowErrorToast} variant="danger" />
        <Toast show={showSuccessToast} setShow={setShowSuccessToast} variant="success" />
      </div>
    );
  }

  return <p>Something went wrong fetching the schedule, please try again later.</p>;
}

export default Schedule;

Schedule.propTypes = {
  user: PropTypes.string.isRequired,
  schedule: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      start: PropTypes.string,
      end: PropTypes.string,
    })
  ).isRequired,
};
