import React, { useState } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import PropTypes from "prop-types";
import { roundToNearestHours } from "date-fns";
import { getOffsetFromUTC } from "../../utils/dateUtils";

function NewShiftForm(props) {
  const {
    closeAll,
    setMentorNameOrEmail,
    mentorNameOrEmail,
    foundMentors,
    isValid,
    search,
  } = props;

  const formatDateForInput = (currentDate) => {
    const date = new Date(currentDate);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}:00`;
  };
  const initialStartTime = formatDateForInput(
    roundToNearestHours(new Date(), { roundingMethod: "floor" })
  );

  const initialFormData = {
    program: "",
    isTi: false,
    startTime: initialStartTime,
    note: "",
    mentorId: null,
    mentorName: null,
  };
  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (event) => {
    const { value, name, checked } = event.target;

    if (name === "mentorNameOrEmail") {
      setMentorNameOrEmail(value);
    }

    if (name === "isTi") {
      return setFormData({ ...formData, [name]: checked });
    }

    if (name === "startTime") {
      const fixedValue = formatDateForInput(
        roundToNearestHours(value, { roundingMethod: "floor" })
      );
      return setFormData({ ...formData, [name]: fixedValue });
    }

    return setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(event.target, event.currentTarget);
    if (formData.program && formData.startTime) {
      props.createShift(formData);
    }
  };

  const addMentorToForm = (mentor) => {
    setFormData({ ...formData, mentorId: mentor.id, mentorName: mentor.fullname });
    setMentorNameOrEmail("");
  };

  return (
    <Form
      onSubmit={handleSubmit}
      noValidate
      className="d-flex flex-column align-content-center justify-content-center flex-shrink-1"
    >
      <h1 style={{ marginBottom: "1em" }}>Create shift</h1>

      <Form.Group
        style={{ display: "flex", gap: "1em", alignItems: "center", marginBottom: "1em" }}
      >
        <Form.Select
          id="program"
          aria-describedby="programBlock"
          placeholder="Mentor Name Or Email"
          size="lg"
          onChange={handleChange}
          value={formData.program}
          name="program"
        >
          <option>Select a program</option>
          <option value="Web">Web</option>
          <option value="Cyber">Cyber</option>
          <option value="Data">Data</option>
        </Form.Select>
        <Form.Check // prettier-ignore
          type="switch"
          id="isTi"
          label={`Is a TI?`}
          name="isTi"
          style={{ flexShrink: 0 }}
          onChange={handleChange}
          value={formData.isTi}
        />
      </Form.Group>

      <Form.Group style={{ marginBottom: "1em" }}>
        <Form.Label htmlFor="tiNewTime">Start Time (Local Time)</Form.Label>
        <Form.Control
          type="datetime-local"
          id="tiNewTime"
          aria-describedby="tiNewTimeBlock"
          placeholder="Mentor Name Or Email"
          size="lg"
          value={formData.startTime}
          onChange={handleChange}
          name="startTime"
        />
      </Form.Group>
      <Form.Label htmlFor="mentorNameOrEmail">Mentor Email || Name</Form.Label>
      {formData.mentorName && <p>Currently selected Mentor: {formData.mentorName}</p>}
      <InputGroup hasValidation>
        <Form.Control
          type="text"
          id="mentorNameOrEmail"
          name="mentorNameOrEmail"
          aria-describedby="mentorNameEmailBlock"
          placeholder="Mentor Name Or Email"
          size="lg"
          value={mentorNameOrEmail}
          onChange={handleChange}
          onKeyDown={event => {
            if(event.key === "Enter"){
              search(event)
            }
          }}
          isInvalid={!isValid}
        />
        <Button variant="primary" id="mentorNameEmailBlock" onClick={search}>
          Search
        </Button>
      </InputGroup>
      {foundMentors && mentorNameOrEmail
        ? foundMentors.map((mentor) => (
            <div
              key={mentor.id}
              className="d-flex flex-row mt-4 mb-4 align-items-start justify-content-between"
            >
              <p>{mentor.fullname}</p>
              <div>
                <Button
                  className="w-100"
                  variant="primary"
                  onClick={() => addMentorToForm(mentor)}
                >
                  Confirm
                </Button>
              </div>
            </div>
          ))
        : null}

      <Form.Group>
        <Form.Label htmlFor="tiNewTime">Shift note</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          onChange={handleChange}
          value={formData.note}
          name="note"
        />
      </Form.Group>

      <Form.Group style={{ display: "flex", gap: "1em" }}>
        <Button className="mt-4" variant="primary" type="submit">
          Create Shift
        </Button>
        <Button className="mt-4" variant="secondary" onClick={closeAll}>
          Close
        </Button>
      </Form.Group>
    </Form>
  );
}

export default NewShiftForm;

// Transfer.propTypes = {
//   setShowTransferModal: PropTypes.func.isRequired,
//   search: PropTypes.func.isRequired,
//   selectedShift: PropTypes.shape({
//     title: PropTypes.string,
//     start: PropTypes.instanceOf(Date),
//     end: PropTypes.instanceOf(Date),
//     tags: PropTypes.string,
//   }).isRequired,
//   mentorNameOrEmail: PropTypes.string,
//   setMentorNameOrEmail: PropTypes.func.isRequired,
//   foundMentors: PropTypes.arrayOf(PropTypes.shape({
//     id: PropTypes.number,
//     fullname: PropTypes.string,
//   })),
//   setFoundMentors: PropTypes.func.isRequired,
//   claimShift: PropTypes.func.isRequired,
//   isValid: PropTypes.bool,
// };
