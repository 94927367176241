import React, { useState, useCallback, useEffect } from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import enUS from "date-fns/locale/en-US";
import PropTypes from "prop-types";

// Need to manually import css when using npm libraries in rails
import "react-big-calendar/lib/css/react-big-calendar.css";

const locales = {
  "en-US": enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek: () => startOfWeek(new Date(), { weekStartsOn: 1 }),
  getDay,
  locales,
});

function CalendarWrapper({
  formattedSchedule,
  onRangeChange,
  onSelectEvent,
  user,
}) {
  const [view, setView] = useState("week");

  const onView = useCallback((newView) => setView(newView), [setView]);

  const eventPropGetter = useCallback((event) => {
    return {
      ...(event.user_id !== user && {
        style: {
          backgroundColor: "#808080",
          borderColor: "#808080",
        },
      }),
      ...(event.user_id === user && {
        style: {
          backgroundColor: "#0C6EFD",
          borderColor: "#0C6EFD",
        },
      }),
      ...(event.tags.includes("TI") && {
        style: {
          backgroundColor: "#242F40",
          borderColor: "#242F40",
        },
      }),
      ...(event.title.includes("Available") && {
        style: {
          backgroundColor: "#009E60",
          borderColor: "#009E60",
        },
      }),
    };
  }, []);

  return (
    <Calendar
      localizer={localizer}
      events={formattedSchedule}
      views={["week", "day"]}
      view={view}
      startAccessor="start"
      endAccessor={({ end }) => new Date(end.getTime() - 1)}
      style={{ height: 500 }}
      className="mt-5 w-100"
      onRangeChange={onRangeChange}
      onView={onView}
      dayLayoutAlgorithm="no-overlap"
      eventPropGetter={eventPropGetter}
      onSelectEvent={onSelectEvent}
      scrollToTime={new Date()}
    />
  );
}

export default CalendarWrapper;

CalendarWrapper.propTypes = {
  formattedSchedule: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      start: PropTypes.instanceOf(Date),
      end: PropTypes.instanceOf(Date),
    })
  ).isRequired,
  onRangeChange: PropTypes.func.isRequired,
  onSelectEvent: PropTypes.func.isRequired,
  user: PropTypes.string.isRequired,
};
